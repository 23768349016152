<template>
<article class="section">
<div class="container">
    <header class="block">
        <h1 class="title">GDPR Verzoeken</h1>
    </header>
    <section class="block">
        <b-table
            :data="requests"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"
            default-sort="id"
        >
            <b-table-column field="id" :visible="false" sortable/><!-- Hack for default sort -->
            <b-table-column field="created_at" label="Datum" cell-class="is-vcentered" v-slot="props">
                {{ props.row.created_at|formatTimestamp }}
            </b-table-column>
            <b-table-column field="status" label="Status" cell-class="is-vcentered" v-slot="props">
                <span class="tag" :class="getStatusClass(props.row.status)">
                    {{ props.row.status|formatStatus|titleCase }}
                </span>
            </b-table-column>
            <b-table-column field="organisation_id" label="Organisatie" cell-class="is-vcentered" v-slot="props">
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation">
                            {{ organisation.name }}
                        </template>
                        <b-skeleton :animated="true" :active="props.row.organisation_id && !organisation"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="status" label="Koppeling" cell-class="is-vcentered" v-slot="props">
                {{ props.row.service_class }}
            </b-table-column>
            <b-table-column field="identifier" label="Kenmerk" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.identifier == props.row.service_id">
                    {{ props.row.service_type|titleCase }} ID:
                </template>
                {{ props.row.identifier }}
                <template v-if="props.row.reference">
                    (Verzoek ID: {{ props.row.reference }})
                </template>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Geen verzoeken gevonden</div>
            </template>
        </b-table>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            organisations: state => state.organisation.all,
            requests: state => state.gdpr.all,
            isLoading: state => state.gdpr.isLoading,
        }),
    },
    filters: {
        formatStatus (status) {
            // Switch would also work, but I hate switches
            if (status === 'pending')
                return 'Open';
            else if (status ==='processing')
                return 'Verwerken';
            else if (status ==='data_available')
                return 'Download beschikbaar';
            else if (status ==='success')
                return 'Success';
            else if (status ==='error')
                return 'Fout';
            else if (status ==='rejected')
                return 'Afgewezen';
            else
                return 'Onbekend';
        },
    },
    methods: {
        getOrganisation (organisationId) {
            return this.organisations.find(o => o.id == organisationId);
        },
        getStatusClass (status) {
            if (status === 'pending')
                return ''; // default styling
            else if (status === 'processing')
                return 'is-info';
            else if (status === 'data_available' || status === 'success')
                return 'is-success';
            else if (status === 'error' || status === 'rejected')
                return 'is-danger';
            else
                return 'is-warning';
        },
        async loadOrganisations() {
            // async so this call can run in the background
            if (this.requests) {    
                const ids = [...new Set(this.requests
                    .filter(r => r.organisation_id && !this.getOrganisation(r.organisation_id))
                    .map(r => r.organisation_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('organisation/loadView', {filter: {ids}});
            }
        }
    },
    created () {
        this.$store.dispatch('gdpr/ensureData');
    },
    watch: {
        requests: {
            handler () {
                // TODO: Make analogous to invoicelist once gdpr is paginated
                this.loadOrganisations();
            },
            immediate: true,
        }
    }
};
</script>
